export enum ExternalHref {
  thresholdDiscord = "https://discord.gg/WXK9PC6SRF",
  metamaskHomePage = "https://metamask.io/",
  randomBeaconNodeDocs = "https://docs.threshold.network/guides/threshold-applications/tbtc-v2-client-setup",
  btcRecoveryAddress = "https://github.com/keep-network/tbtc-v2/blob/main/docs/rfc/rfc-1.adoc",
  tbtcBridgeGithub = "https://github.com/keep-network/tbtc-v2/blob/853bc8ff0601c82bb02cfaa0c904ba98d7d92fe5/solidity/contracts/bridge/Bridge.sol",
  tBTCBrdigeAudit = "https://leastauthority.com/blog/audits/audit-of-keep-network-tbtc-bridge-v2/",
  vendingMachineAudit = "https://www.certik.com/projects/threshold-network",
  thresholdStakingAudit = "https://chainsecurity.com/security-audit/threshold-network/",
  installTaho = "https://taho.xyz/",
  setTahoAsDefaultWallet = "https://tahowallet.notion.site/Taho-is-interfering-with-Metamask-or-another-wallet-6005819d4e8748f5b7d1ed788da8826c",
  tBTCRecoveryGuide = "https://github.com/keep-network/tbtc-v2/blob/main/typescript/scripts/README.adoc",
  btcConfirmations = "https://en.bitcoin.it/wiki/Confirmation",
  mintersAndGuardiansDocs = "https://blog.threshold.network/minters-guardians-and-a-strong-tbtc/",
  thresholdBlog = "https://blog.threshold.network/",
  tBTCDuneDashboard = "https://dune.com/threshold/tbtc",
}
