import { createIcon } from "@chakra-ui/icons"

export const Bitcoin = createIcon({
  displayName: "Bitcoin",
  defaultProps: { color: "white" },
  viewBox: "0 0 20 27",
  path: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.8158 11.1506C20.2133 8.48937 18.1871 7.05875 15.4164 6.10437L16.3152 2.49937L14.1202 1.9525L13.2452 5.4625C12.6689 5.31875 12.0764 5.18312 11.4877 5.04875L12.3689 1.51562L10.1758 0.96875L9.27644 4.5725C8.79894 4.46375 8.33019 4.35625 7.87519 4.24312L7.8777 4.23188L4.85145 3.47625L4.26769 5.82C4.26769 5.82 5.89582 6.19312 5.86145 6.21625C6.7502 6.43812 6.91145 7.02625 6.88395 7.4925L5.8602 11.5994C5.92145 11.615 6.00082 11.6375 6.08832 11.6725L5.85707 11.615L4.42145 17.3681C4.3127 17.6381 4.03707 18.0431 3.41582 17.8894C3.4377 17.9212 1.82082 17.4912 1.82082 17.4912L0.731445 20.0037L3.5877 20.7156C4.11895 20.8487 4.63957 20.9881 5.15144 21.1194L4.24332 24.7662L6.43519 25.3131L7.3352 21.7056C7.93332 21.8681 8.51457 22.0181 9.08332 22.1594L8.18707 25.75L10.3814 26.2969L11.2896 22.6575C15.0314 23.3656 17.8458 23.08 19.0289 19.6962C19.9833 16.9712 18.9821 15.3994 17.0133 14.3737C18.4471 14.0419 19.5277 13.0987 19.8158 11.1506ZM14.8014 18.1812C14.1227 20.9062 9.5352 19.4337 8.04707 19.0637L9.25207 14.2331C10.7396 14.6044 15.5083 15.3394 14.8014 18.1812ZM15.4796 11.1112C14.8608 13.59 11.0421 12.3306 9.8027 12.0219L10.8952 7.64062C12.1346 7.94937 16.1246 8.52562 15.4796 11.1112Z"
        fill="currentColor"
      />
    </svg>
  ),
})
